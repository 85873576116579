@import "../../scss/variables";

.auth {
    &__center {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__container {
        background-color: $color-bg-elem;
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        flex-flow: center;
        padding: 2rem;
        height: auto;
        position: relative;
        box-shadow: $box-shadow-1;
        border-radius: 1rem;
    }

    &__title {
        font-size: 2rem;
        text-align: center;
        margin: 0.3em 0;
        padding: 0.5em;
        font-weight: bold;
        color: $color-primary;
        background-color: $color-bg-elem;
        text-transform: uppercase;
    }

    &__bottom {
        &-link {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            color: $color-primary;
            text-decoration: none;
            padding-top: 1rem;
        }
    }
}
